const ATLCHA_DEFAULT_PREFIX = 'altcha_'
const ATLCHA_INTEGRATION_PREFIX = 'altchaInteg_'
const ATLCHA_TELECHARGEMENT_PREFIX = 'altchaTelechargement_'

function incrementAltcha(name) {
    //contient un seul, _ ni au début, ni à la fin
    const regex = '^[^_]+_[^_]+$'
    try {
        if (name.match(regex)) {
            let parts = name.split('_')
            parts[1] = parseInt(parts[1]) + 1
            return parts.join('_')
        }
        return ''
    } catch (error) {
        console.error('Altcha key error : ', error)
        return ''
    }
}

module.exports = {
    ATLCHA_DEFAULT_PREFIX,
    ATLCHA_INTEGRATION_PREFIX,
    ATLCHA_TELECHARGEMENT_PREFIX,
    incrementAltcha,
}
