<template>
    <section>
        <form class="container" @submit.prevent="envoiMessage">
            <TitreReference
                :reference="'Contacter'"
                :texte="'une préfecture'"
                class="padding-bottom-40-px" />
            <div
                class="
                    font-marianne-light
                    bold
                    padding-top-10-px padding-bottom-10-px
                ">
                Sélectionner un département
            </div>
            <SelectForm
                ref="selectDepartements"
                :nomVarSelect="'selectDepartements'"
                :listeAffiche="this.departements"
                :width="512" />
            <div
                class="
                    flex
                    font-marianne-light
                    bold
                    padding-top-10-px padding-bottom-10-px
                ">
                Message
                <help-widget
                    :titre="'Format du message'"
                    :texte="'Doit contenir entre 50 et 2000 caractères '" />
            </div>
            <textarea
                id="message"
                class="textAreaForm margin-bottom-20-px"
                placeholder="Entrez votre message ici"
                v-model="message"
                ref="message"
                cols="20"
                rows="7" />
            <input type="hidden" v-model="fakeField" />
            <div class="padding-bottom-20-px">
                <AltchaComponent />
            </div>
            <button class="bouton background_bleu_fonce texte_blanc no-border">
                <img
                    src="../../assets/img/tnaau/Icon_publier.svg"
                    class="margin-right-10-px" />
                Envoyer
            </button>
        </form>
    </section>
</template>

<script>
import axios from 'axios'
import TitreReference from '@/components/widgets/titreReference/TitreReference'
import SelectForm from '@/components/widgets/selectForm/SelectForm'
import HelpWidget from '@/components/widgets/helpWidget/HelpWidget'
import { notificationLogout } from '../../tools/authent'
import { notNullCheck } from '../../tools/inputCheck'
import { checkInput } from '../../tools/inputCheck'
import AltchaComponent from '@/components/widgets/altchaWidget/AltchaWidget.vue'
import EventBus from '@/bus'
import * as eventTypes from '@/bus/event-types'

export default {
    name: 'ContactPrefecture',
    components: { TitreReference, SelectForm, HelpWidget, AltchaComponent },
    data() {
        return {
            departement: '',
            departements: [],
            message: '',
            fakeField: '',
            openDepartements: false,
            altcha: '',
        }
    },
    methods: {
        goto(url) {
            window.location.href = url
        },
        envoiMessage() {
            let formulaireValid = true
            // On vérifie qu'un département ait bien été sélectionné
            var selectDepartements = this.$refs.selectDepartements.vmodel
            let inputDepartement = this.$refs.selectDepartements.$el.firstChild
            let formulaireValidCentre = checkInput(
                inputDepartement,
                notNullCheck(selectDepartements)
            )

            // On vérifie qu'un message ait bien été saisie
            var refMessage = this.$refs.message
            if (this.message.length < 50 || this.message.length > 2000) {
                formulaireValid = false
                refMessage.classList.add('input_red')
                this.$notify({
                    group: 'general-notification',
                    title: 'Format du message incorrect',
                    text: 'Le message doit contenir entre 50 et 2000 caractères.',
                    type: 'error',
                    duration: 5000,
                })
            } else {
                refMessage.classList.remove('input_red')
            }

            if (this.altcha === '') {
                this.$notify({
                    group: 'general-notification',
                    title: 'Erreur',
                    text: 'Captcha invalide.',
                    type: 'error',
                    duration: 5000,
                })
                return
            }

            if (formulaireValid && formulaireValidCentre) {
                if (
                    this.fakeField === '' &&
                    this.$route.name === 'ContactPrefecture'
                ) {
                    axios
                        .post(
                            '/message',
                            {
                                idDepartement: selectDepartements,
                                contenu: this.message,
                                raison: this.fakeField,
                                altcha: this.altcha,
                            },
                            {
                                headers: {
                                    'Content-Type': 'application/json',
                                    Authorization:
                                        'Bearer ' +
                                        this.$store.state.user.token,
                                },
                            }
                        )
                        .then((response) => {
                            if (response.status === 200) {
                                if (response.data !== null) {
                                    this.$notify({
                                        group: 'general-notification',
                                        title: 'Message envoyé avec succès',
                                        text: '',
                                        type: 'success',
                                        duration: 5000,
                                    })
                                    this.goto('/#/')
                                }
                            }
                        })
                        .catch((e) => {
                            if (
                                e.response.data.message.includes(
                                    'Un des paramètres suivants est null'
                                )
                            ) {
                                this.$notify({
                                    group: 'general-notification',
                                    title: 'Erreur',
                                    text: "Erreur lors de l'envoi du message",
                                    type: 'error',
                                    duration: 5000,
                                })
                            } else {
                                if (e.response.status === 403) {
                                    this.$notify({
                                        group: 'general-notification',
                                        title: 'Interdiction',
                                        text: "Vous n'avez pas l'autorisation d'envoyer ce message.",
                                        type: 'error',
                                        duration: 5000,
                                    })
                                }
                                notificationLogout(this, e)
                            }
                        })
                } else {
                    this.goto('/#/')
                }
            }
        },
        getListeDepartements() {
            axios
                .get('/departementutilisateur', {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + this.$store.state.user.token,
                    },
                })
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data !== null) {
                            var i
                            for (i = 0; i < response.data.length; i++) {
                                this.departements.push({
                                    nom:
                                        response.data[i].nom +
                                        ' (' +
                                        response.data[i].codeInsee +
                                        ')',
                                    id: response.data[i].idDepartement,
                                })
                                if (i === 0) {
                                    this.departement =
                                        response.data[i].idDepartement
                                }
                            }
                        }
                    }
                })
                .catch((e) => {
                    if (e.response.status === 403) {
                        this.$notify({
                            group: 'general-notification',
                            title: 'Interdiction',
                            text: "Vous n'avez pas l'autorisation d'accéder à ce département",
                            type: 'error',
                            duration: 5000,
                        })
                    }
                    notificationLogout(this, e)
                })
        },
    },
    mounted() {
        EventBus.$on(eventTypes.CAPTCHA_VERIFIED, (args) => {
            this.altcha = args.payload
        })
        this.getListeDepartements()
    },
    destroyed() {
        EventBus.$off(eventTypes.CAPTCHA_VERIFIED)
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.message-textarea {
    width: 618px;
    height: 143px;
    resize: none;
    margin-bottom: 30px;
}
</style>
