<template>
    <section>
        <div
            class="middle-container flex-row-center"
            v-if="this.isLoginVisible">
            <div class="left_block inline-flex-column-left">
                <div class="logo_tnaau" />
                <div class="titre-tnaau">
                    Table nationale d’acheminement des appels d’urgence
                </div>
            </div>
            <form
                @submit.prevent="clickConnexion"
                class="right_block inline-flex-column-center"
                v-if="this.isLoginStatus() === 0">
                <div class="padding-bottom-40-px">
                    <div class="ligne_connexion" />
                    <div class="titre-connexion">Connexion</div>
                    <div class="ligne_connexion" />
                </div>
                <div class="padding-bottom-40-px">
                    <div class="sous-titre-connexion padding-left-100-px">
                        {{ this.appInfo.appMode }}
                    </div>
                </div>
                <div class="inline-flex-column-left padding-bottom-20-px">
                    <label for="username" class="titre-champ"
                        >Identifiant</label
                    >
                    <input
                        type="email"
                        id="username"
                        autocomplete="username"
                        class="input_custom background_gris_clair"
                        v-model="login"
                        placeholder="Email"
                        required />
                </div>
                <div class="inline-flex-column-left padding-bottom-20-px">
                    <label for="current-password" class="titre-champ"
                        >Mot de passe</label
                    >
                    <input
                        type="password"
                        id="current-password"
                        autocomplete="current-password"
                        class="input_custom background_gris_clair"
                        placeholder="Mot de passe"
                        v-model="password"
                        required />
                </div>
                <div class="zone_bouton">
                    <button
                        class="
                            bouton
                            background_rouge
                            texte_blanc
                            no-border
                            margin-bottom-10-px
                        ">
                        Connexion
                    </button>
                    <button
                        type="button"
                        class="
                            mdp_oublie
                            background_page
                            texte_noir
                            no-select no-border
                        "
                        @click="switchLostPassword">
                        Mot de passe oublié ?
                    </button>
                </div>
            </form>
            <form
                @submit.prevent="clickValidation"
                class="right_block inline-flex-column-center"
                v-if="this.isLoginStatus() === 1">
                <div class="flex-row padding-bottom-40-px">
                    <div class="ligne_connexion" />
                    <div class="titre-connexion">Connexion</div>
                    <div class="ligne_connexion" />
                </div>
                <div class="inline-flex-column-left padding-bottom-20-px">
                    <div for="one-time-code" class="titre-champ">
                        Code de validation
                    </div>
                    <input
                        type="text"
                        inputmode="numeric"
                        id="one-time-code"
                        class="input_custom background_gris_clair"
                        placeholder="Code"
                        v-model="code"
                        @input="checkCode"
                        ref="inputCode" />
                </div>
                <div class="zone_bouton flex-row">
                    <button
                        class="
                            bouton
                            background_rouge
                            texte_blanc
                            no-border
                            margin-bottom-10-px
                        ">
                        Confirmer
                    </button>
                    <button
                        type="button"
                        class="
                            bouton
                            background_gris
                            texte_blanc
                            no-border
                            margin-bottom-10-px margin-left-30-px
                        "
                        @click="logout">
                        Retour
                    </button>
                </div>
            </form>
        </div>
        <div v-if="!isOptimize" class="version_navigateur">
            Le navigateur que vous utilisez n'est pas optimisé. Veuillez
            utiliser Mozilla Firefox version 60 + ou bien Microsoft Edge
        </div>

        <LostPassword v-if="lostPasswordVisible" />
        <Register v-if="registerVisible" />
        <Integration v-if="integrationVisible" />
        <div class="footer">Version 1.9.10</div>
        <div class="info" v-if="this.appInfo.appMode == 'MINISTERE'">
            <p>
                Pour toute difficulté ou question:
                <a href="mailto:tnaau@interieur.gouv.fr"
                    >tnaau@interieur.gouv.fr</a
                >
            </p>
        </div>
        <div class="info" v-if="this.appInfo.appMode == 'OPERATEUR'">
            <p>
                Vous êtes opérateur et vous souhaitez avoir accès aux PDAAU ou
                vous rencontrez un problème d'accès<br />cliquez ici:
                <a href="mailto: pdaau-cced.dge@finances.gouv.fr">
                    pdaau-cced.dge@finances.gouv.fr</a
                >
            </p>
        </div>
    </section>
</template>
<script>
import axios from 'axios'
import LostPassword from './LostPassword'
import Register from './Register'
import Integration from '../pages/Integration'
import Roles from '../../tools/Roles'
import { notificationLogout, logout } from '@/tools/authent'
export default {
    name: 'Login',
    components: { LostPassword, Register, Integration },
    data() {
        return {
            login: '',
            password: '',
            code: '',
            isLoginVisible: true,
            lostPasswordVisible: false,
            registerVisible: false,
            integrationVisible: false,
            isOptimize: false,
            appInfo: '',
        }
    },
    mounted() {
        this.detectionNavigateur()
        if (this.$store.state.user.login !== '') {
            this.login = this.$store.state.user.login
        }
        if (this.$router.currentRoute.name === 'LostPassword') {
            this.isLoginVisible = false
            this.registerVisible = false
            this.lostPasswordVisible = true
            this.integrationVisible = false
        }
        if (this.$router.currentRoute.name === 'Register') {
            this.isLoginVisible = false
            this.lostPasswordVisible = false
            this.registerVisible = true
            this.integrationVisible = false
        }
        if (this.$router.currentRoute.name === 'Integration') {
            this.isLoginVisible = false
            this.lostPasswordVisible = false
            this.registerVisible = false
            this.integrationVisible = true
        }
        this.getAppInfo()
    },
    methods: {
        goto(url) {
            window.location.href = url
        },
        logout() {
            logout(this)
        },
        switchLostPassword() {
            this.isLoginVisible = false
            this.lostPasswordVisible = true
            this.registerVisible = false
        },
        clickConnexion() {
            let formData = new FormData()
            formData.append('username', this.login)
            axios
                .post(
                    '/authn',
                    { username: this.login },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            password: this.password,
                        },
                    }
                )
                .then((response) => {
                    if (response.status === 200) {
                        if (response.headers.authorization) {
                            this.$store.state.user.token =
                                response.headers.authorization.split(' ')[1]
                            this.$store.state.user.id = response.data.id
                            this.$store.state.user.login = response.data.email
                            this.$store.state.user.profil = response.data.profil
                            this.$store.state.user.timedOut = false
                            this.$store.state.user.droitFranceEntiere =
                                response.data.droitFranceEntiere

                            //Temporaire le temps de trouver une solution avec les différentes versions de docker
                            if (
                                response.data.profil === Roles.ADMINISTRATEUR ||
                                response.data.profil ===
                                    Roles.PREFECTURE_MODIFICATION ||
                                response.data.profil ===
                                    Roles.PREFECTURE_CONSULTATION ||
                                response.data.profil ===
                                    Roles.DIRECTION_METIER ||
                                response.data.profil === Roles.GESTIONNAIRE_OCE
                            ) {
                                this.$store.state.user.fullLogged = true
                                //Actualisation de la page au cas ou il y est une maj
                                this.$router.go()
                            }
                            this.$store.commit('saveStore')
                        } else {
                            //On redirige vers passage2
                            this.goto(response.data.url)
                            return
                        }
                    }
                })
                .catch((e) => {
                    if (e.response.status === 401) {
                        this.$notify({
                            group: 'general-notification',
                            title: 'Erreur de connexion',
                            text: 'Identifiant ou mot de passe invalide',
                            type: 'warn',
                            duration: 5000,
                        })
                    }
                    if (e.response.status === 500) {
                        this.$notify({
                            group: 'general-notification',
                            title: 'Erreur serveur',
                            text: "Erreur lors de l'éxecution de la requête",
                            type: 'error',
                            duration: 5000,
                        })
                    }
                })
        },
        clickValidation() {
            let formData = new FormData()
            formData.append('username', this.login)
            axios
                .post(
                    '/2fa',
                    {
                        username: this.login,
                        validationCode: this.code,
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            password: this.password,
                            Authorization:
                                'Bearer ' + this.$store.state.user.token,
                        },
                    }
                )
                .then((response) => {
                    if (response.status === 200) {
                        this.$store.state.user.token =
                            response.headers.authorization.split(' ')[1]
                        this.$store.state.user.fullLogged = true
                        this.$store.commit('saveStore')
                        //Actualisation de la page au cas ou il y est une maj
                        this.$router.go()
                    } else {
                        console.log('probleme')
                    }
                })
                .catch((e) => {
                    if (e.response.status === 401) {
                        this.$notify({
                            group: 'general-notification',
                            title: 'Erreur de connexion',
                            text: 'Code de validation invalide',
                            type: 'error',
                            duration: 5000,
                        })
                    }
                    if (e.response.status === 500) {
                        this.$notify({
                            group: 'general-notification',
                            title: 'Erreur serveur',
                            text: 'Erreur lors de la requête',
                            type: 'error',
                            duration: 5000,
                        })
                    }
                })
        },
        isLoginStatus() {
            if (this.$store.state.user.id !== '') {
                if (this.$store.state.user.fullLogged) {
                    return 2 // full logged
                } else {
                    return 1 // loggé en operateur provisoire
                }
            } else {
                return 0 // pas loggé
            }
        },
        detectionNavigateur() {
            var nAgt = navigator.userAgent
            var browserName = navigator.appName
            var fullVersion = '' + parseFloat(navigator.appVersion)
            var majorVersion = parseInt(navigator.appVersion, 10)
            var nameOffset, verOffset, ix

            // In Opera, the true version is after "Opera" or after "Version"
            if ((verOffset = nAgt.indexOf('Opera')) != -1) {
                browserName = 'Opera'
                fullVersion = nAgt.substring(verOffset + 6)
                if ((verOffset = nAgt.indexOf('Version')) != -1)
                    fullVersion = nAgt.substring(verOffset + 8)
            }
            // In MSIE, the true version is after "MSIE" in userAgent
            else if ((verOffset = nAgt.indexOf('MSIE')) != -1) {
                browserName = 'Microsoft Internet Explorer'
                fullVersion = nAgt.substring(verOffset + 5)
            }
            // In Chrome, the true version is after "Chrome"
            else if ((verOffset = nAgt.indexOf('Chrome')) != -1) {
                browserName = 'Chrome'
                fullVersion = nAgt.substring(verOffset + 7)
            }
            // In Safari, the true version is after "Safari" or after "Version"
            else if ((verOffset = nAgt.indexOf('Safari')) != -1) {
                browserName = 'Safari'
                fullVersion = nAgt.substring(verOffset + 7)
                if ((verOffset = nAgt.indexOf('Version')) != -1)
                    fullVersion = nAgt.substring(verOffset + 8)
            }
            // In Firefox, the true version is after "Firefox"
            else if ((verOffset = nAgt.indexOf('Firefox')) != -1) {
                browserName = 'Firefox'
                fullVersion = nAgt.substring(verOffset + 8)
            }
            // In most other browsers, "name/version" is at the end of userAgent
            else if (
                (nameOffset = nAgt.lastIndexOf(' ') + 1) <
                (verOffset = nAgt.lastIndexOf('/'))
            ) {
                browserName = nAgt.substring(nameOffset, verOffset)
                fullVersion = nAgt.substring(verOffset + 1)
                if (browserName.toLowerCase() == browserName.toUpperCase()) {
                    browserName = navigator.appName
                }
            }
            // trim the fullVersion string at semicolon/space if present
            if ((ix = fullVersion.indexOf(';')) != -1)
                fullVersion = fullVersion.substring(0, ix)
            if ((ix = fullVersion.indexOf(' ')) != -1)
                fullVersion = fullVersion.substring(0, ix)

            majorVersion = parseInt('' + fullVersion, 10)
            if (isNaN(majorVersion)) {
                fullVersion = '' + parseFloat(navigator.appVersion)
                majorVersion = parseInt(navigator.appVersion, 10)
            }

            if (
                (browserName == 'Firefox' && majorVersion >= 60) ||
                (browserName == 'Chrome' && majorVersion >= 80)
            ) {
                this.isOptimize = true
            }
        },
        getAppInfo() {
            axios
                .get('/application/info')
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data !== null) {
                            this.appInfo = response.data
                            this.$store.state.integration =
                                response.data.integration
                        }
                    }
                })
                .catch((e) => {
                    notificationLogout(this, e)
                })
        },
    },
}
</script>
<style scoped>
.version_navigateur {
    color: #ff0000;
    font-family: 'Marianne Regular';
    font-size: 18px;
    text-align: center;
}

/* container principal pour le login */
.left_block {
    width: 500px;
    height: 250px;
}
.right_block {
    width: 305px;
    height: 291px;
}
.titre-connexion {
    width: 160px;
}

.footer {
    text-align: center;
    font-family: 'Marianne Regular';
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 5px;
}
.info {
    text-align: center;
    font-family: 'Marianne Regular';
    font-size: small;
}
</style>
