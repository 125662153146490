<template>
    <altcha-widget
        :challengejson="challenge"
        strings='{"label":"Je ne suis pas un robot",
            "error":"Vérification échouée. Réessayez plus tard.",
            "expired":"Vérification expirée. Réessayez.",
            "label":"Je ne suis pas un robot",
            "verifying":"Vérification en cours...",
            "waitAlert":"Vérification en cours... Veuillez patienter.",
            "verified":"Vérifié"}'
        maxnumber="500000"
        @verified="handleVerified($event)">
    </altcha-widget>
</template>
<script>
import EventBus from '@/bus'
import * as eventTypes from '@/bus/event-types'
import axios from 'axios'
export default {
    name: 'AltchaComponent',
    data() {
        return {
            challenge: '{}',
        }
    },
    methods: {
        handleVerified(ev) {
            EventBus.$emit(eventTypes.CAPTCHA_VERIFIED, {
                payload: ev.detail.payload,
            })
        },

        getChallengeJson() {
            axios.get('/form_submit').then((response) => {
                this.challenge = JSON.stringify(response.data)
            })
        },
    },
    mounted() {
        this.getChallengeJson()
    },
}
</script>
